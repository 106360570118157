import { useTranslation } from 'react-i18next'

import { WithResourceType } from '@/permissions'

type AppBarProps = {
  label: string
  basePath: string
  path?: string
  icon: string
}

export const useSideBar = () => {
  const { t } = useTranslation()
  const adminSidebarItem: WithResourceType<AppBarProps>[] = [
    {
      label: t('sidebar.quote_request'),
      basePath: '/admin/quotesrequestlist',
      path: '/admin/quotesrequestlist/ocean',
      icon: 'insert_drive_file',
      resourceType: 'QUOTE_REQUEST',
    },
    {
      label: t('sidebar.price_management'),
      basePath: '/admin/price-managements',
      path: '/admin/price-managements/fcl',
      icon: 'format_list_bulleted',
      resourceType: 'PRICE_MANAGEMENT',
    },
    {
      label: t('sidebar.purchase_price'),
      basePath: '/admin/purchase',
      path: '/admin/purchase/fcl',
      icon: 'request_quote',
      resourceType: 'PURCHASE',
    },
    {
      label: t('sidebar.selling_price'),
      basePath: '/admin/selling',
      path: '/admin/selling/fcl',
      icon: 'request_quote',
      resourceType: 'SELLING',
    },
    {
      label: t('sidebar.quotes'),
      basePath: '/admin/quotes',
      path: '/admin/quotes/ocean',
      icon: 'transform',
      resourceType: 'QUOTES',
    },
    {
      label: t('sidebar.schedule'),
      basePath: '/admin/schedule',
      icon: 'event_available',
      resourceType: 'SCHEDULE',
    },
    {
      label: t('sidebar.booking'),
      basePath: '/admin/booking',
      icon: 'check_box',
      resourceType: 'BOOKING',
    },
    {
      label: t('sidebar.shipment'),
      basePath: '/admin/shipment',
      icon: 'place',
      resourceType: 'SHIPMENT',
    },
    {
      label: 'Backlogs',
      basePath: '/admin/backlogs',
      icon: 'storage',
      resourceType: 'SHIPMENT', // NOTE: リソース的には SHIPMENT と同じなので権限も同じにしている.
    },
    {
      label: t('sidebar.invoice'),
      basePath: '/admin/invoice/individual',
      icon: 'local_atm',
      resourceType: 'BILLING',
    },
    {
      label: t('sidebar.company_management'),
      basePath: '/admin/company-management',
      path: '/admin/company-management/forwarder/ocean',
      icon: 'manage_accounts',
      resourceType: 'NETWORK',
    },
    {
      label: t('sidebar.user_company'),
      basePath: '/admin/user-companies',
      icon: 'apartment',
      resourceType: 'USER_COMPANY',
    },
    {
      label: t('sidebar.common_data'),
      basePath: '/admin/master-data',
      path: '/admin/master-data/cargoes',
      icon: 'add_circle',
      resourceType: 'COMMODITY',
    },
    {
      label: t('sidebar.milestone'),
      basePath: '/admin/milestone',
      icon: 'add_task',
      resourceType: 'MILESTONE',
    },
    {
      label: t('sidebar.flight_schedule'),
      basePath: '/admin/air-transport-schedules',
      icon: 'flight_takeoff',
      resourceType: 'AIR_TRANSPORT_SCHEDULE',
    },
  ]

  return {
    adminSidebarItem,
  }
}
